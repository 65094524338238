// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-templates-course-template-js": () => import("./../../../src/components/templates/courseTemplate.js" /* webpackChunkName: "component---src-components-templates-course-template-js" */),
  "component---src-components-templates-news-js": () => import("./../../../src/components/templates/news.js" /* webpackChunkName: "component---src-components-templates-news-js" */),
  "component---src-components-templates-personal-page-template-js": () => import("./../../../src/components/templates/personalPageTemplate.js" /* webpackChunkName: "component---src-components-templates-personal-page-template-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-consulting-js": () => import("./../../../src/pages/consulting.js" /* webpackChunkName: "component---src-pages-consulting-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-projects-education-js": () => import("./../../../src/pages/projects/education.js" /* webpackChunkName: "component---src-pages-projects-education-js" */),
  "component---src-pages-projects-math-js": () => import("./../../../src/pages/projects/math.js" /* webpackChunkName: "component---src-pages-projects-math-js" */),
  "component---src-pages-projects-physics-js": () => import("./../../../src/pages/projects/physics.js" /* webpackChunkName: "component---src-pages-projects-physics-js" */),
  "component---src-pages-projects-software-js": () => import("./../../../src/pages/projects/software.js" /* webpackChunkName: "component---src-pages-projects-software-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-quarks-js": () => import("./../../../src/pages/quarks.js" /* webpackChunkName: "component---src-pages-quarks-js" */)
}

